<template>
  <div class="login-register">
    <div class="contain">
      <div class="big-box" :class="{active:isLogin}">
        <div class="big-contain" key="bigContainLogin" v-if="isLogin">
          <div class="btitle">账户登录</div>
          <div class="bform">
            <input type="email" placeholder="邮箱" v-model="userEmail">
            <input type="password" placeholder="密码" v-model="userPwd">
          </div>
          <button class="bbutton" @click="login">登录</button>
        </div>
        <div class="big-contain" key="bigContainRegister" v-else>
          <div class="btitle">创建账户</div>
          <div class="bform">
            <input type="text" placeholder="用户名" v-model="userName">
            <input type="email" placeholder="邮箱" v-model="userEmail">
            <input type="password" placeholder="密码" v-model="userPwd">
          </div>
          <button class="bbutton" @click="register">注册</button>
        </div>
      </div>
      <div class="small-box" :class="{active:isLogin}">
        <div class="small-contain" key="smallContainRegister" v-if="isLogin">
          <div class="stitle">你好，朋友!</div>
          <p class="scontent">开始注册，和我们一起旅行</p>
          <button class="sbutton" @click="changeType">注册</button>
        </div>
        <div class="small-contain" key="smallContainLogin" v-else>
          <div class="stitle">欢迎回来!</div>
          <p class="scontent">与我们保持联系，请登录你的账户</p>
          <button class="sbutton" @click="changeType">登录</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex';
import {ElMessage} from "element-plus";
import axiosInstance from '@/axios';
import router from "@/router";

export default {
  name: 'login-register',
  setup() {
    const store = useStore();
    // const instance = getCurrentInstance()
    const existed = computed(() => store.state.existed);
    const isLogin = computed(() => store.state.isLogin);
    const emailError = computed(() => store.state.emailError);
    const userEmail = computed({
      get: () => store.state.form.useremail,
      set: value => store.commit('setUserEmail', value)
    });
    const userPwd = computed({
      get: () => store.state.form.userpwd,
      set: value => store.commit('setUserPwd', value)
    });
    const userName = computed({
      get: () => store.state.form.username,
      set: value => store.commit('setUserName', value)
    });
    const changeType = () => {
      store.commit('setIsLogin', store.state.isLogin === false);
      store.commit('setForm', {
        username: '',
        useremail: '',
        userpwd: ''
      })
    }
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    }
    const login = async () => {
      if (!store.state.form.useremail || !store.state.form.userpwd) {
        alert("填写不能为空！");
        return;
      } else if (!validateEmail(store.state.form.useremail)) {
        ElMessage.error("邮箱格式错误！")
        store.commit('setEmailError', true);
        return;
      }
      try {
        const res = await axiosInstance.post('/userlogin', {
          email: store.state.form.useremail,
          password: store.state.form.userpwd
        })
        switch (res.data) {
          case 0:
            ElMessage.success("登录成功！");
            router.push('/profile', () => {
              console.log("Navigation successful!");
            }, (error) => {
              console.error("Navigation failed:", error);
            });
            break;
          case -1:
            ElMessage.error("用户不存在！");
            store.commit("setEmailError", true);
            break;
          case 1:
            ElMessage.error("密码错误！");
            store.commit("setPasswordError", true);
            break;
        }
      } catch (err) {
        ElMessage.error(err.toString());
        console.log(err);
      }
    }
    const register = async () => {
      if (!store.state.form.username || !store.state.form.useremail || !store.state.form.userpwd) {
        alert("填写不能为空！");
        return;
      } else if (!validateEmail(store.state.form.useremail)) {
        ElMessage.error("邮箱格式错误！")
        return;
      }
      try {
        const res = await axiosInstance.post('/useradd', {
          username: store.state.form.username,
          email: store.state.form.useremail,
          password: store.state.form.userpwd
        })
        switch (res.data) {
          case 0:
            ElMessage.success("注册成功！");
            await login();
            break;
          case -1:
            ElMessage.error("用户已存在！");
            store.state.existed = true;
            break;
        }
      } catch (err) {
        ElMessage.error(err.toString())
        console.log(err);
      }
    }
    return {
      changeType,
      emailError,
      existed,
      login,
      isLogin,
      register,
      userEmail,
      userPwd,
      userName,
    }
  }
}
</script>


<style scoped>
.login-register {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.contain {
  width: 60%;
  height: 60%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 3px #f0f0f0,
  0 0 6px #f0f0f0;
}

.big-box {
  width: 70%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 30%;
  transform: translateX(0%);
  transition: all 1s;
}

.big-contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btitle {
  font-size: 1.5em;
  font-weight: bold;
  color: rgb(57, 167, 176);
}

.bform {
  width: 100%;
  height: 40%;
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.bform input {
  width: 50%;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 2em;
  background-color: #f0f0f0;
}

.bbutton {
  width: 20%;
  height: 40px;
  border-radius: 24px;
  border: none;
  outline: none;
  background-color: rgb(57, 167, 176);
  color: #fff;
  font-size: 0.9em;
  cursor: pointer;
}

.small-box {
  width: 30%;
  height: 100%;
  background: linear-gradient(135deg, rgb(57, 167, 176), rgb(56, 183, 145));
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  transition: all 1s;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.small-contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
}

.scontent {
  font-size: 0.8em;
  color: #fff;
  text-align: center;
  padding: 2em 4em;
  line-height: 1.7em;
}

.sbutton {
  width: 60%;
  height: 40px;
  border-radius: 24px;
  border: 1px solid #fff;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 0.9em;
  cursor: pointer;
}

.big-box.active {
  left: 0;
  transition: all 0.5s;
}

.small-box.active {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  transform: translateX(-100%);
  transition: all 1s;
}
</style>

// router/index.js
import {createRouter, createWebHistory} from 'vue-router'
import LoginRegister from '../views/loginRegister.vue'
import Profile from '../views/UserMain.vue'
import UserInfoShow from "../views/UserInfoShow.vue";
import UserChat from "../views/UserChat.vue";
import UserInfoModify from "../views/UserInfoModify.vue";
import UserLogout from "../views/UserLogout.vue"

const routes = [
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        children: [
            {
                path: 'show',
                component: UserInfoShow
            },
            {
                path: 'chat',
                component: UserChat
            },
            {
                path: 'modify',
                component: UserInfoModify
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginRegister,
    },
    {
        path: '/logout',
        name: 'logout',
        component: UserLogout,
    },
    {
        path: '/',
        redirect: '/profile'
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: Profile
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

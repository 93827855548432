<template>
  <div class="user-form-container">
    <el-form :model="form" :rules="rules" label-width="120px">
      <div class="form-content">
        <div class="form-inputs">
          <el-form-item label="ID" prop="id">
            <el-input v-model="form.id" disabled/>
          </el-form-item>
          <el-form-item label="用户名" prop="name">
            <el-input v-model="form.name"/>
          </el-form-item>
          <el-form-item label="邮箱" prop="mail">
            <el-input v-model="form.mail"/>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="form.password" type="password"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </div>
        <div class="form-avatar">
          <el-upload
              ref="avatarUploader"
              class="avatar-uploader"
              action="https://chat.pk5ls20.com/api/api/user/modified/avatar"
              :on-success="handleAvatarSuccess"
              :auto-upload=false
              :with-credentials=true
              :limit=1
          >
            <img :src="`https://chat.pk5ls20.com/api/api/user/avatar?user_id=${form.id}`" class="avatar"/>
            <template #tip>
              <div class="el-upload__tip">
                上传新头像
              </div>
            </template>
          </el-upload>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {ref, reactive, computed} from 'vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {useStore} from "vuex";
import axiosInstance from "@/axios";

export default {
  setup() {
    const store = useStore();
    const avatarUploader = ref(null);
    const form = computed(() => {
      return {
        id: store.state.form.id,
        name: store.state.form.username,
        mail: store.state.form.useremail,
        password: ""
      }
    })
    const rules = reactive({
      name: [
        {required: true, message: '用户名不能为空', trigger: 'blur'}
      ],
      mail: [
        {required: true, message: '邮箱不能为空', trigger: 'blur'},
        {pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, message: '邮箱格式不正确', trigger: 'blur'}
      ]
    });
    const imageUrl = ref(`https://chat.pk5ls20.com/api/api/user/avatar?user_id=${form.value.id}`);
    const handleAvatarSuccess = () => {
      ElMessage.success('Avatar uploaded successfully!')
    }
    const onSubmit = async () => {
      try {
        await ElMessageBox.confirm(
            '确认修改信息吗?',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }
        );
        const userInfo = {
          id: form.value.id,
          username: form.value.name,
          email: form.value.mail,
        };
        if (form.value.password) {
          userInfo.password = form.value.password;
        }
        const resInfo = await axiosInstance.post('/usermodifiedinfo', userInfo);
        avatarUploader.value.submit();
        if (resInfo.data.success) {
          ElMessage.success("用户信息修改成功！")
        } else {
          ElMessage.error("用户信息修改失败！")
        }
      } catch (e) {
        ElMessage.error(e)
      }
    };
    return {
      form,
      onSubmit,
      imageUrl,
      handleAvatarSuccess,
      avatarUploader,
      rules
    };
  }
};
</script>

<style scoped>
.user-form-container {
  position: relative;
  bottom: -100px;
  left: 400px;
  width: 600px;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.form-content {
  display: flex;
  justify-content: space-between;
}

.form-inputs {
  flex: 1;
  margin-right: 20px;
}

.form-avatar {
  flex-shrink: 0;
}


</style>
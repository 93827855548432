import {createStore} from 'vuex';

export default createStore({
    state: {
        isLogin: false,
        emailError: false,
        passwordError: false,
        existed: false,
        form: {
            username: '',
            useremail: '',
            userpwd: '',
            id: '',
        },
        timeless: "0min/60min",
        currentMsg: [],
        chatInputTmp: "",
        currentUserPageShow: "",
    },
    mutations: {
        setIsLogin(state, value) {
            state.isLogin = value;
        },
        setEmailError(state, value) {
            state.emailError = value;
        },
        setPasswordError(state, value) {
            state.passwordError = value;
        },
        setExisted(state, value) {
            state.existed = value;
        },
        setForm(state, value) {
            state.form = value;
        },
        setUserName(state, value) {
            state.form.username = value;
        },
        setUserEmail(state, value) {
            state.form.useremail = value;
        },
        setUserPwd(state, value) {
            state.form.userpwd = value;
        },
        setId(state, value) {
            state.form.id = value;
        },
        setTimeless(state, value) {
            state.timeless = value;
        },
        setCurrentMsg(state, value) {
            state.currentMsg = value;
        },
        setChatInputTmp(state, value) {
            state.chatInputTmp = value;
        },
        pushCurrentMsg(state, value) {
            state.currentMsg.push(value);
        },
        setCurrentUserPageShow(state, value) {
            state.currentUserPageShow = value;
        }
    },
    actions: {
        sendMsg(context, value) {
            context.commit('pushCurrentMsg', value);
        },
        setCurrentMsgAction(context, value) {
            context.commit('setCurrentMsg', value);
        }
    },
});

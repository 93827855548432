<template>
  <div class="chat-container">
    <div class="chat-box">
      <div v-for="message in messages" :key="message.id" class="message">
        <img :src="`https://chat.pk5ls20.com/api/api/user/avatar?user_id=${message.id}`" alt="User Avatar" class="avatar">
        <div class="message-content">
          <div class="message-header">
            <span class="username">{{ message.id }}</span>
            <span class="timestamp">{{ "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + message.time }}</span>
          </div>
          <div class="message-bubble">
            <span>{{ message.msg }}</span>
          </div>
          <div class="message-end">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-input">
    <div class="input-container">
      <el-input v-model="userInput" type="textarea" placeholder="Please input"/>
      <el-divider direction="vertical" border-style="hidden"/>
      <el-button type="primary" @click="clickTest" size="large">Send</el-button>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, ref} from 'vue';
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import axiosInstance from "@/axios";

export default {
  name: 'UserChat',
  setup() {
    const store = useStore();
    const messages = computed(() => store.state.currentMsg);
    const userInput = computed({
      get: () => store.state.chatInputTmp,
      set: value => store.commit('setChatInputTmp', value)
    })
    const getCurrentDateTime = () => {
      const now = new Date();
      const dateStr = now.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
      return dateStr.replace(/\//g, '-');
    }
    const ws = ref(null);
    ws.value = new WebSocket('ws://chat.pk5ls20.com/api/api/chat');
    ws.value.onopen = async function () {
      const fetchRecord = (await axiosInstance.get('/chat/record')).data.data;
      ElMessage.success(`获取到了${fetchRecord.length}条历史消息`)
      await store.dispatch('setCurrentMsgAction', fetchRecord)
      console.log("WebSocket is open now.");
      ElMessage.success({
        message: 'WebSocket is open now.',
        type: 'success'
      });
    };
    ws.value.onmessage = async function (event) {
      console.log("Message from server:", event.data);
      const tmp_msg = JSON.parse(event.data);
      if (tmp_msg.way === "peer" || tmp_msg.type !== "msg") {
        return;
      }
      await store.dispatch('sendMsg', {
        id: tmp_msg.id,
        time: getCurrentDateTime(),
        way: "broadcast",
        type: "msg",
        msg: tmp_msg.data,
      });
      ElMessage.success({
        message: event.data,
        type: 'success'
      });
    };
    ws.value.onerror = function (error) {
      console.error("WebSocket Error", error);
      ElMessage.error({
        message: error,
        type: 'error'
      });
    };
    ws.value.onclose = function (event) {
      if (event.wasClean) {
        console.log(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
        ElMessage.info({
          message: `Connection closed cleanly, code=${event.code}, reason=${event.reason}`,
          type: 'error'
        });
      } else {
        console.error("Connection died");
        ElMessage.error({
          message: "Connection died",
          type: 'error'
        })
      }
    };
    const clickTest = () => {
      ws.value.send(store.state.chatInputTmp);
      userInput.value = "";
    }
    onBeforeUnmount(() => {
      ElMessage.info("组件即将关闭")
      if (ws.value) {
        ElMessage.success("WebSocket is closed now.")
        ws.value.close();
      }
    });
    return {
      clickTest,
      userInput,
      messages,
      getCurrentDateTime
    }
  }
};
</script>

<style scoped>

.chat-container {
  position: relative;
  bottom: -50px;
  left: 400px;
  width: 1000px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.chat-input {
  position: relative;
  bottom: -70px;
  left: 400px;
  padding: 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
}

.chat-box {
  flex: 3;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.input-container {
  display: flex;
  width: 980px;
  justify-content: space-between;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-header {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.message-end {
  height: 20px;
  display: block;
}

.username {
  font-weight: bold;
}

.timestamp {
  color: #999;
}

.message-bubble {
  padding: 10px;
  background-color: #e9e9e9;
  border-radius: 10px;
  max-width: 500px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

</style>
<template>
	<div id="app">
    <router-view></router-view>
	</div>
</template>
  
<script>
  export default {
	name: 'App'
  }
</script>
  
<style scoped>
	#app {
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f5;
	}
</style>
<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>你的信息</span>
      </div>
    </template>
    <div v-for="(info, index) in displayInfo" :key="index" class="text item">{{ info }}</div>
  </el-card>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  setup() {
    const store = useStore();
    const displayInfo = computed(() => {
      return [
        `id: ${store.state.form.id}`,
        `name: ${store.state.form.username}`,
        `email: ${store.state.form.useremail}`,
        `timeless: ${store.state.timeless}`,
      ];
    });
    return {
      displayInfo,
    };
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  position: relative;
  bottom: -100px;
  left: 400px;
  width: 600px;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #f5f5f5;
}
</style>
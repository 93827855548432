<template>
  <el-menu
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      router
  >
    <el-menu-item index="/">🥵</el-menu-item>
    <el-menu-item index="/profile/chat">开始聊天吧！</el-menu-item>
    <div class="flex-grow"/>
    <el-menu-item index="/profile/show" >查看用户信息</el-menu-item>
    <el-menu-item index="/profile/modify">修改用户信息</el-menu-item>
    <el-menu-item v-if="!isUserLogged">注册</el-menu-item>
    <el-menu-item v-if="!isUserLogged">登陆</el-menu-item>
    <el-menu-item v-if="isUserLogged" >{{`欢迎, ${userID}`}}</el-menu-item>
    <el-sub-menu index="more">
      <template #title>更多</template>
      <el-menu-item index="/logout">退出登录</el-menu-item>
    </el-sub-menu>
  </el-menu>
  <router-view></router-view>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import {ElMessage} from "element-plus";
import axiosInstance from "@/axios";
import router from "@/router";

export default {
  setup() {
    const store = useStore();
    const isUserLogged = computed({
          get: () => store.state.isLogin,
          set: value => store.commit('setIsLogin', value)
    })
    const userID = computed(() => store.state.form.username);
    const check = async () => {
      try {
        store.commit('setIsLogin', false)
        const res = await axiosInstance.get('/userinfo', {})
        if (res.data.status.success) {
          ElMessage.success("鉴权成功！")
          store.commit('setIsLogin', true)
          store.commit('setUserName', res.data.data.username)
          store.commit('setUserEmail', res.data.data.email)
          store.commit('setId', res.data.data.id)
          store.commit('setTimeless', res.data.status.timeless)
        } else {
          store.state.isLogin = false;
          store.commit('setIsLogin', false)
          store.commit('setUserName', "")
          store.commit('setUserEmail', "")
          store.commit('setId', "")
          store.commit('setTimeless', "0min/60min")
          setTimeout(() => {
            router.push('/login')
          }, 3000);
        }
        console.log(res.data)
      } catch (e) {
        ElMessage.error(e.toString());
        setTimeout(() => {
          router.push('/login')
        }, 3000);
      }
    }
    check();
    return {
      isUserLogged,
      userID
    }
  }
}
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}
</style>
